<template>
  <div class="product-list-layout withTopBar flex_box fullbox">
    <van-nav-bar title="收款记录" left-text="返回" left-arrow border safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
    <div class="classify-left">
      <div class="item" :class="curClassify.type == item.type ? 'activeClassify' : ''" v-for="(item,index) in classifyData" :key="'type'+index" @click="selectClassify(index)">
        {{item.title}}
      </div>
    </div>
    <div class="classify-right flex_one" ref="scroll">
      <div class="loading" v-show="loading">
        <van-loading type="spinner" color="#1989fa" />
      </div>
      <van-empty v-show="!loading && productData.length==0" description="暂无数据" />
      <div class="product" v-for="(item,index) in productData" :key="'product'+index" @click="jumpPage(index)">
        <div class="send_date pdb10 color_gray_two font14" v-if="curClassify.type == '3'">
          配送日期：{{item.send_day}}
        </div>
        <div class="flex_box">
          <div class="pic">
            <img class="" :src="item.pic" />
          </div>
          <div class="flex_one pdl10">
            <div class="font16 pdt5 pdb5">{{item.title}}</div>
            <div class="font14 color_gray_two" v-if="curClassify.type != '4'">
              规格：{{item.norm}}
            </div>
            <div class="font14 color_gray_two" v-if="curClassify.type == '4'">
              {{item.price_text}}
            </div>
            <div class="font14 color_gray_two" v-if="curClassify.type == '4'">
              共{{item.total_num}}瓶
            </div>
            <div class="font14 color_gray_two" v-if="curClassify.type == '3'">
              剩余：{{item.last_num}}份
            </div>
            <div class="price" v-if="curClassify.type != '4' && curClassify.type != '3' && curClassify.type != '5'">
              ￥{{item.selfprice}}
            </div>
            <div class="dis_price" v-if="curClassify.type == '4'">活动价：{{item.dis_price}}元</div>
            <div class="flex_box align_center space_between pdr15" v-if="curClassify.type == '3'">
              <div class="single_price">￥{{item.price}}</div>
              <div class="add_btn">
                <div class="setbtn iconfont icon-jia font18"></div>
              </div>
            </div>
            <div class="flex_box align_center space_between pdr15 mt5" v-if="curClassify.type == '5'">
              <div class="single_price">￥{{item.price}}</div>
              <div class="buy_btn">立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name:"product-list",
  data(){
    return{
      user:{},
      activeKey:0,
      loading:false,
      isMore:true,
      page:1,
      rowCount:10,
      productData:[],
      classifyData:[
        {type: 1, title: "新鲜牛奶"},
        {type: 2, title: "新鲜酸奶"},
        {type: 4, title: "优惠活动"}
      ],
      curClassify:{type: 1, title: "新鲜牛奶"}
    }
  },
  created(){
    let user = localStorage.getItem("cxyUserinfo")
    user ? this.user = JSON.parse(user) : ''
    this.classify.type ? this.curClassify = JSON.parse(JSON.stringify(this.classify)) : {}
    this.index()
  },
  mounted(){
    this.$refs.scroll.addEventListener('scroll',this.handleScroll,true);
  },
  methods:{
    index(){
      const _this = this
      _this.loading = true
      _this.$api.common.getProductList({
        station_id:_this.user.station_id,
        type:_this.curClassify.type,
        page:_this.page,
        rowCount:_this.rowCount
      }).then(res=>{
        _this.loading = false
        if(res.code =='0' && res.data){
          let result = _this.productData.concat(res.data.rows)
          _this.productData = result
          _this.page++
          if(res.data.rows.length < _this.rowCount){
            _this.isMore = false
          }
        }
      }).catch(() => {
        _this.loading = false;
      })
    },
    handleScroll(){
      const _this = this

      const scrollTop = this.$refs.scroll.scrollTop
      // 获取可视区的高度
      const windowHeight = this.$refs.scroll.clientHeight
      // 获取滚动条的总高度
      const scrollHeight = this.$refs.scroll.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight ) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        if(_this.isMore && !_this.loading){
          _this.index()
        }
      }
    },
    selectClassify(index){//切换分类
      let classify = this.classifyData[index]
      if(this.curClassify.type != classify.type ){
        this.$store.commit('setClassify', classify)
        this.curClassify = classify
        this.productData = []
        this.isMore = true
        this.page = 1
        this.index()
      }
    },
    jumpPage(index){
      this.$store.commit('setProduct', this.productData[index])
      this.$store.commit('setquickOrder',{})
      this.$router.push({
        name: this.curClassify.type=='4' ? 'package-purchase' : 'quick-order'
      })
    },
    onChange(index) {
      this.$notify({ type: 'primary', message: index });
    },
    onClickLeft(){
      this.$router.push({
        name: "home"
      })
    }
  },
  computed:{
    ...mapState(["classify"])
  }
}
</script>

<style lang="scss">
@import "./product-list.scss"
</style>